import { moduleLogger } from "./util";
import { sendCustomError } from "@otto-ec/global-resources/apm";

const attributeNameId = "id";
const attributeNameFeature = "feature";
const attributeNameTeam = "team";
const attributeNameVariationId = "variation-id";
const attributeNameProductId = "product-id";
const attributeNameSku = "sku";
const attributeNamePartnerId = "partner-id";

const log = moduleLogger.scope("cashback");

/*                                                                                                   */
/*                                                                              */
const MAX_CLEANUP_PERIOD_IN_MILLIS = 20 * 24 * 60 * 60 * 1000;

export class CashbackElement extends HTMLElement {
	static observedAttributes = [attributeNameId, attributeNameFeature, attributeNameTeam, attributeNameVariationId, attributeNameProductId, attributeNameSku, attributeNamePartnerId];
	private _isConnected = false;

	override get isConnected() {
		return this._isConnected;
	}

	override set isConnected(value: boolean) {
		this._isConnected = value;
	}

	constructor() {
		super(); /*                                     */
		this.isConnected = false;
	}

	async attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		log.info("Attribute update: ", name, oldValue, newValue, this.isConnected);
		this.firstElementChild?.remove();
		if (this.isConnected) {
			await this.callBackend();
		}
	}

	async connectedCallback() {
		this.isConnected = true;
		await this.callBackend();
	}

	async callBackend() {
		const id = this.getAttribute(attributeNameId);
		const urlSearchParams = this.getSearchParams();

		log.info("Calling tag for %s with %s", id, JSON.stringify(urlSearchParams));

		let tries = 0;
		let success = false;
		const status_code = [];

		while (tries++ < 3 && !success) {
			let response: Response;
			try {
				response = await fetch(`/benefit-tag/cashback?${urlSearchParams}`, {
					headers: {
						Accept: "text/html;charset=UTF-8",
						"X-Requested-With": "XMLHttpRequest",
					},
				});
			} catch (error) {
				log.warn("tag cashback request returns an error!", error);

				if (error instanceof DOMException) {
					status_code.push(error.name);
				} else {
					status_code.push(error);
				}
				continue;
			}

			status_code.push(response.status);
			success = response.ok;

			if (success) {
				/*                          */
				response.text().then((text) => {
					this.innerHTML = text;
					initCashback(this);
				});
				break;
			}
			log.warn("tag cashback endpoint returns a non ok response!", response.status);
		}

		if (!success) {
			sendCustomError("ft9-cashback-not-ok", {
				urlSearchParams: urlSearchParams.toString(),
				status_code: status_code.join(":"),
				tries: tries - 1,
			});
		}
	}

	private getSearchParams() {
		const urlSearchParams = new URLSearchParams({});

		function addToSearchParam(paramName: string, value: string | null) {
			if (value != null) {
				urlSearchParams.append(paramName, value);
			}
		}

		addToSearchParam("feature", this.getAttribute(attributeNameFeature));
		addToSearchParam("team", this.getAttribute(attributeNameTeam));
		addToSearchParam("sku", this.getAttribute(attributeNameSku));
		addToSearchParam("partnerId", this.getAttribute(attributeNamePartnerId));
		return urlSearchParams;
	}
}

function initCashback(initializingWrapper: CashbackElement) {
	const cashback = initializingWrapper.firstElementChild;
	if (!cashback || !(cashback instanceof HTMLElement)) {
		return;
	}

	if (cashback.dataset.expirationDate) {
		const expirationDate = new Date(cashback.dataset.expirationDate);

		const delayInMillis: number = Math.min(Math.max(expirationDate.getTime() - new Date().getTime(), 1), MAX_CLEANUP_PERIOD_IN_MILLIS);
		window.setTimeout(() => {
			cashback.remove();
		}, delayInMillis);
	}
}

customElements.define("ft9-cashback-v1", CashbackElement);
